import(/* webpackMode: "eager" */ "/home/runner/work/stsndotapp/stsndotapp/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/stsndotapp/stsndotapp/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/stsndotapp/stsndotapp/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/stsndotapp/stsndotapp/src/app/PosthogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/home/runner/work/stsndotapp/stsndotapp/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/stsndotapp/stsndotapp/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/stsndotapp/stsndotapp/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/home/runner/work/stsndotapp/stsndotapp/src/trpc/react.tsx");
